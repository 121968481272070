<template>
  <v-container data-cy="category-group-container" class="py-0">
    <!--    if moduleHookType is neither trust, search or divider we can check if displayType is carousel -->
    <template v-if="isSuitableForCarouselAndFeatured && isDisplayTypeCarousel">
      <v-row
        no-gutters
        class="mx-n3"
        data-cy="category-group-container-carousel"
      >
        <CategoryComponent
          data-cy="category-group-container-carousel-component"
          :cta-path="catalog.call_to_action_path"
          :cta-text="catalog.call_to_action_text"
          :displayType="catalog.display_type"
          :hookType="moduleHookType"
          :isFeatured="catalog.featured"
          :title="catalog.title"
          :items="catalog.items"
          :seeAllQueryParams="getSeeAllSearchParams(catalog)"
          :displayPage="displayPage"
          showSeeAllBtn
        />
      </v-row>
    </template>

    <!--    if moduleHookType is neither trust, search or divider we can check if displayType is either feature-one-item or feature-one-item -->
    <template
      v-else-if="isSuitableForCarouselAndFeatured && isDisplayTypeFeatureOneTwo"
    >
      <v-row no-gutters data-cy="category-group-container-featured">
        <ConciergeFeaturedItem
          data-cy="category-group-container-featured-item"
          :catalog="catalog"
        />
      </v-row>
    </template>

    <!--    if moduleHookType is trust, search or divider, ignore display type and proceed -->
    <template v-else-if="isTrustModuleHookType">
      <v-row data-cy="category-group-container-trust" no-gutters>
        <ConciergeTrustItem
          data-cy="category-group-container-trust-component"
          :catalog="catalog"
        />
      </v-row>
    </template>

    <template v-else-if="isDividerModuleHookType">
      <v-row no-gutters data-cy="category-group-container-divider">
        <ConciergeDividerItem
          :title="catalog.title"
          data-cy="category-group-container-divider-item"
        />
      </v-row>
    </template>

    <template v-else-if="isSearchModuleHookType">
      <v-row no-gutters>
        <ConciergeSearchModule :catalog="catalog" />
      </v-row>
    </template>
    <template v-else-if="isBannerModuleHookType">
      <v-row no-gutters>
        <ConciergeBannerItem :info="catalog" />
      </v-row>
    </template>
    <template v-else-if="isCabinClassModuleHookType">
      <v-row no-gutters>
        <v-col cols="auto">
          <ConciergeCabinClassSelector :catalog="catalog" />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import CategoryComponent from './CategoryComponent';
import ConciergeTrustItem from './ConciergeTrustItem';
import ConciergeDividerItem from './ConciergeDividerItem';
import ConciergeFeaturedItem from './ConciergeFeaturedItem';
import ConciergeSearchModule from '@/modules/concierge-v3/features/explore-list/ConciergeSearchModule';
import ConciergeBannerItem from '@/modules/concierge-v3/features/explore-list/ConciergeBannerItem';
import ConciergeCabinClassSelector from './ConciergeCabinClassSelector';
export default {
  name: 'CategoryGroupContainer',
  components: {
    ConciergeSearchModule,
    CategoryComponent,
    ConciergeTrustItem,
    ConciergeDividerItem,
    ConciergeFeaturedItem,
    ConciergeBannerItem,
    ConciergeCabinClassSelector,
  },
  props: {
    catalog: {
      type: Object,
    },
    displayPage: {
      type: String,
      default: '',
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    catalogDisplayType() {
      /* This represents the display type selected for a module hook.
       * DisplayType of module hooks is NOT the same as their Type.
       * DisplayType in a module hook makes its contents show in a carousel, feature-one-item, feature-two-items.
       * Carousel or featured displayTypes are currently suitable for most module hook types.
       * Carousel or featured displayTypes are NOT suitable for module hook types 'divider', 'trust', and 'search'.
       */
      return this.catalog.display_type;
    },

    moduleHookType() {
      /* Module hook type is NOT the same as catalog displayType in WP.
       * Module hook type in WP is catalog.type and refers to concierge service's module hook type.
       * The existing types are: booking, booking-nearby, divider, health,
       *  information, place, search, shop-offering, shop-category, transportation, and trust. */
      return this.catalog.type;
    },

    isDisplayTypeCarousel() {
      return this.catalogDisplayType === 'carousel';
    },

    isDisplayTypeFeatureOneTwo() {
      return (
        this.catalogDisplayType === 'feature-one-item' ||
        this.catalogDisplayType === 'feature-two-items'
      );
    },

    isTrustModuleHookType() {
      return this.moduleHookType === 'trust';
    },

    isDividerModuleHookType() {
      return this.moduleHookType === 'divider';
    },

    isSearchModuleHookType() {
      return this.moduleHookType === 'search';
    },

    isCabinClassModuleHookType() {
      return this.moduleHookType === 'cabin-class';
    },

    isSuitableForCarouselAndFeatured() {
      /* Module hook types trust, divider, search are incompatible with carousel/featured. Therefore, we ignore their display type selection.
       * Other module hook types can use their displayType which is configurable via concierge admin. */
      return (
        !this.isTrustModuleHookType &&
        !this.isDividerModuleHookType &&
        !this.isSearchModuleHookType &&
        !this.isCabinClassModuleHookType
      );
    },

    isBannerModuleHookType() {
      return this.moduleHookType === 'banner';
    },
  },

  methods: {
    getSeeAllSearchParams(catalog) {
      return !this.showSeeAllBtn
        ? null
        : !!catalog.ctaPath
        ? catalog.ctaPath
        : null;
    },
  },
};
</script>
