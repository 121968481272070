<template>
  <v-dialog v-model="dialog" max-width="430" v-if="hasCabinClasses">
    <template v-slot:activator="{ on }">
      <div class="">
        <v-btn
          class="cabin-class-btn"
          :loading="isLoading"
          v-on="on"
          :min-width="232"
          outlined
          size="large"
          block
        >
          <v-row justify="space-between">
            <v-col cols="auto">
              <span v-if="selectedCabinClass && selectedCabinClass.name">
                {{
                  $t('cabin_class_selected', {
                    cabinClass: selectedCabinClass.name,
                  })
                }}
              </span>
            </v-col>
            <v-col cols="auto">
              <v-icon size="small" class="ml-3">mdi-pencil</v-icon>
            </v-col>
          </v-row>
        </v-btn>
        <GTooltip
          v-if="showTooltip"
          :auto-show-delay="3000"
          :hide-delay="10000"
        >
          {{ $t('select_cabin_class_tooltip') }}
        </GTooltip>
      </div>
    </template>

    <v-card class="rounded-lg pa-3">
      <v-card-title class="card-title pt-3 pl-3">
        {{ $t('select_cabin_class') }}
      </v-card-title>
      <v-container class="mb-2 pa-3">
        <v-row
          v-for="availableCabinClass in cabinClasses"
          :key="availableCabinClass.id"
        >
          <v-col cols="12" class="py-1">
            <v-btn
              class="cabin-class-select-btn justify-space-between"
              block
              outlined
              x-large
              @click="selectCabinClass(availableCabinClass)"
            >
              <span>{{ availableCabinClass.name }}</span>
              <span
                v-if="availableCabinClass.id === selectedCabinClass.id"
                class="selected-text"
              >
                {{ $t('currently_selected') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import GTooltip from './GTooltip';

export default {
  components: {
    GTooltip,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isLoading() {
      return this.cabinClasses.length === 0;
    },
    hasCabinClasses() {
      return this.cabinClasses.length > 1;
    },
  },
  props: {
    cabinClasses: {
      type: Array,
      default: () => [
        {
          id: '1',
          name: 'Economy',
          managementConsoleId: '765432',
        },
        {
          id: '2',
          name: 'Premium',
          managementConsoleId: '123456',
        },
      ],
    },
    selectedCabinClass: {
      type: Object,
      default: () => ({
        id: '1',
        name: 'Economy',
        managementConsoleId: '765432',
      }),
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectCabinClass(cabinClass) {
      this.$emit('select', cabinClass);
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title.card-title {
  color: var(--Base-08-Grey, #2f2f2f);

  /* 600 Semi-Bold/16pt */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 20.8px */
}

.cabin-class-select-btn {
  .selected-text {
    color: #2e8540;

    /* 400 Regular/12pt */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 15.6px */
  }

  border: 1px solid #d1d1d1;
  border-radius: 8px !important;
  color: var(--Base-08-Grey, #2f2f2f);

  /* 400 Regular/16pt */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 20.8px */
}

.cabin-class-btn {
  border: 1px solid #d1d1d1;
  border-radius: 8px !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 1.2;

  &::before {
    display: none;
  }

  &.active {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);

    & svg {
      fill: none;

      * {
        stroke: var(--v-primary-base);
      }
    }
  }
}
</style>
