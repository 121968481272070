<template>
  <div>
    <v-fade-transition>
      <div class="g-tooltip pa-1 px-2 mt-3" v-show="showTooltip">
        <slot>
          <span>I'm a tooltip</span>
        </slot>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
export default {
  props: {
    autoShowDelay: {
      type: Number,
      default: 0,
    },
    hideDelay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showTooltip: false,
      showTooltipTimer: null,
    };
  },
  destroyed() {
    clearTimeout(this.showTooltipTimer);
  },
  methods: {
    setHideTimeout() {
      return setTimeout(() => {
        this.showTooltip = false;
      }, this.hideDelay);
    },
    setAutoShowTimeout() {
      return setTimeout(() => {
        this.showTooltip = true;
        if (this.hideDelay > 0) {
          this.showTooltipTimer = this.setHideTimeout();
        }
      }, this.autoShowDelay);
    },
  },
  created() {
    if (this.autoShowDelay > 0) {
      this.showTooltipTimer = this.setAutoShowTimeout();
    } else {
      this.showTooltip = true;
      if (this.hideDelay > 0) {
        this.showTooltipTimer = this.setHideTimeout();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.g-tooltip {
  position: absolute;
  display: inline-block;
  border-radius: 8px;
  background: var(--Base-09-Grey, #141414);
  color: var(--Base-Pure-White, #fff);

  /* 500 Medium/14px */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */

  &::before {
    content: '';
    position: absolute;
    top: -19px;
    left: 15px;
    width: 10px;
    height: 10px;
    border: 10px solid transparent;
    border-bottom: 10px solid #05090f;
  }
}
</style>
