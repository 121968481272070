<template>
  <CabinClassSelector
    :cabin-classes="catalog.items"
    :selected-cabin-class="selectedCabinClass"
    @select="onCabinclassSelected"
    :show-tooltip="allowCabinClasstooltip"
  ></CabinClassSelector>
</template>
<script>
import CabinClassSelector from '@/modules/common/components/CabinClassSelector';
import Cookies from 'js-cookie';

export default {
  components: {
    CabinClassSelector,
  },
  props: {
    catalog: {
      type: Object,
    },
  },
  computed: {
    selectedCabinClass() {
      return this.$store.getters['concierge/selectedCabinClass'];
    },
    allowCabinClasstooltip() {
      return this.$store.getters['concierge/allowCabinClasstooltip'];
    },
  },
  watch: {
    selectedCabinClass: {
      handler(selectedCabinClassValue, oldSelectedCabinClassValue) {
        if (selectedCabinClassValue == null) {
          this.$store.dispatch(
            'concierge/setSelectedCabinClass',
            this.catalog.items[0]
          );
          Cookies.set('glxCabinClass', JSON.stringify(this.catalog.items[0]));
        } else if (
          selectedCabinClassValue &&
          selectedCabinClassValue.id &&
          oldSelectedCabinClassValue !== selectedCabinClassValue
        ) {
          const { items } = this.catalog;

          if (
            items?.length > 0 &&
            !items.find((item) => item.id === selectedCabinClassValue.id)
          ) {
            this.$store.dispatch('concierge/setSelectedCabinClass', items[0]);
            Cookies.set('glxCabinClass', JSON.stringify(items[0]));
          } else if (items?.length < 1) {
            this.$store.dispatch('concierge/setSelectedCabinClass', null);
            Cookies.remove('glxCabinClass');
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    onCabinclassSelected(cabinClass) {
      this.$store.dispatch('concierge/setSelectedCabinClass', cabinClass);
      Cookies.set('glxCabinClass', JSON.stringify(cabinClass));
    },
  },
};
</script>
